<!--
  PACKAGE_NAME : src\pages\op\ivr\ivrsvr-config.vue
  FILE_NAME : ivrsvr-config
  AUTHOR : hmlee
  DATE : 2024-05-22
  DESCRIPTION : IVR 서버 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
    },
    props: {},
    data() {
      return {
        dataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'ivrServerRefName',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          dataSourceDefaultSortColumn: '+svrOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          apiActionNm: {
            select: 'CC_IVR_SVR_LIST',
            merge: 'CC_IVR_SVR_MERGE',
            delete: 'CC_IVR_SVR_DELETE',
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: true,
            update: true,
            copy: true,
            delete: true,
            customButtons: [], //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
          },
          customEvent: {
            selectionChanged: false,
            initNewRow: false,
            cellPrepared: false,
            rowPrepared: false,
            contentReady: false,
            optionChanged: false,
            exporting: false,
            rowInserting: false,
            rowInserted: false,
            rowUpdating: false,
            rowUpdated: false,
            rowRemoved: false,
            saving: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: true,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              alignment: 'center', // left center right
              visible: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '사이트명*',
              i18n: 'COMMON.WORD.SITE_NM',
              dataField: 'siteId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              requiredRule: {
                messege: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_msgContents('COMMON.WORD.SITE_NM', {defaultValue: '사이트'})}),
              },
            },
            {
              caption: '센터명*',
              i18n: 'COMMON.WORD.TENANT_NM',
              dataField: 'tenantId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
              requiredRule: {
                messege: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_msgContents('COMMON.WORD.TENANT_NM', {defaultValue: '센터'})}),
              },
            },
            {
              caption: 'IVR명*',
              i18n: 'CC.WORD.IVR_NAME'+'*',
              dataField: 'svrNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              requiredRule: {
                messege: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_msgContents('CC.WORD.IVR_NAME', {defaultValue: 'IVR명'})}),
              },
            },
            {
              caption: 'IVR No *',
              dataField: 'sysNo',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              requiredRule: {
                messege: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: 'IVR No'}),
              },
            },
            {
              caption: 'IP *',
              dataField: 'ip',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              requiredRule: {
                messege: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: 'IP'}),
              },
            },
            {
              caption: 'svrUser',
              dataField: 'svrUser',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: 'svrPasswd',
              dataField: 'svrPasswd',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: 'svrMethod',
              dataField: 'svrMethod',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: 'Port',
              dataField: 'svrPort',
              alignment: 'center', // left center right
              visible: true,
              //allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'svrOrd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {},
    created() {},
    mounted() {},
  };
</script>